@font-face {
  font-display: block;
  font-family: "Press Start 2P";
  src: local("Press Start 2P"),
    url("./assets/fonts/PressStart2P-Regular.ttf") format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html {
  /* touch-action: none; */
  user-select: none;
  /* Must comment out for Samsung Internet browser */
  /* overflow: hidden; */
}

body {
  margin: 0;
  font-family: "Press Start 2P", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::placeholder {
  color: rgb(184, 184, 184);
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgb(184, 184, 184);
}

::-ms-input-placeholder {
  color: rgb(184, 184, 184);
}

@media (min-width: 480px) {
  ::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.wrapperTop {
  top: 0 !important;
  transform: translate(-50%, 0%) rotateX(0deg) !important;
}

.wrapperTop2 {
  transform: translate(-50%, -100%) rotateX(180deg) !important;
}

.transformWrapperTop {
  top: 0 !important;
}

.sectionTop {
  overflow-y: scroll !important;
}

a {
  color: inherit;
}

a.hover-link {
  text-decoration: none;
}

a.hover-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

a.description-link {
  color: #254181;
  text-decoration: none;
}